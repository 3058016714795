import React, { Component, createRef } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import TextField from 'components/ui-components-v2/TextField';
import Validator from 'components/data/Validator';
import { StringHelpers } from 'helpers/string.helpers';
import '../styles/main.scss';

/**
 * Copy field
 * Displays a copy field with optional translation features
 */
export default class Copy extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.string,
                updated: PropTypes.bool,
                maxLength: PropTypes.number
            }),
            PropTypes.any
        ]),
        /** Enable or disable multiline textfield */
        multiline: PropTypes.bool,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** Enable to use object with value in it, otherwise, output directly */
        useValueObject: PropTypes.bool,
        /** This is a readonly object */
        readOnly: PropTypes.any,
        /** This is a disabled object */
        disabled: PropTypes.bool,
        /** The type of field */
        fieldType: PropTypes.string,
        /** the className of the component */
        className: PropTypes.string,
        /** the end adornment of the component */
        endAdornment: PropTypes.string,
        /** If the input should be focussed */
        autoFocus: PropTypes.bool,
        /** This is needed in order to find this component with Cypress */
        dataCyPrefix: PropTypes.string | undefined
    };

    static defaultProps = {
        useValueObject: true,
        multiline: false,
        readOnly: false,
        disabled: false,
        fieldType: 'text',
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        // Allow two modes, output object or string
        let stateValue;
        if (props.useValueObject) {
            stateValue = '';
            if (props.value && props.value.value) {
                stateValue = props.value.value;
            }
        }
        // Fallback for when issue with data
        else {
            stateValue = typeof props.value === 'string' ? props.value : '';
        }

        this.state = {
            value: stateValue,
            updated: false,
            warnings: [],
            errors: []
        };

        this.input = createRef();
    }

    /**
     * This validates the value in the input on the first render.
     * Autofocuses the input if autoFocus is set to true.
     */
    componentDidMount() {
        if (this.props.validators) {
            this.validate();
        }

        if (this.props.autoFocus && this.input && this.input.current) {
            this.input.current.focus();
        }
    }

    /**
     * Component did update. This checks whether we need to display a new value (which causes a rerender)
     * @param {*} prevProps
     * @param {*} prevState
     */
    componentDidUpdate = (prevProps) => {
        // Set a new value externally, update the state
        if (this.props.useValueObject && this.props.value && this.props.value !== prevProps.value && this.props.value.value !== this.state.value) {
            this.setState({ value: this.props.value.value });
        }
        // No value object, but still update
        else if (!this.props.useValueObject && this.props.value && this.props.value !== prevProps.value && this.props.value != this.state.value) {
            this.setState({ value: this.props.value });
        }
        // Empty in case it was externally cleared
        else if (!this.props.value && prevProps.value && this.state.value) {
            this.setState({ value: '' });
        }
    };

    /**
     * Change the text
     * @param {*} text
     */
    handleTextChange = (value) => {
        this.setState({ value, updated: true });

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.updateModel, 300);
    };

    /**
     * Update the model
     */
    updateModel = () => {
        const { onMutation, maxLength, useValueObject, validators } = this.props;
        const { value, updated } = this.state;

        if (useValueObject) {
            onMutation({
                value: value,
                maxLength: maxLength,
                updated: updated,
                copy: true
            });
        } else {
            onMutation(value);
        }

        if (validators) {
            this.validate();
        }
    };

    /**
     * Field validator
     */
    validate = () => {
        const { value } = this.state;
        const { validators } = this.props;

        const warnings = [];
        const errors = [];

        // Validate the value on change
        if (validators) {
            validators.forEach((item) => {
                if (!Validator.validateValue(value, item)) {
                    if (item.severity && item.severity == 'warning') {
                        warnings.push(item.message);
                    } else {
                        errors.push(item.message);
                    }
                }
            });
        }
        this.setState({
            warnings: warnings,
            errors: errors
        });
    };

    /**
     * Handle the paste event.
     * Remove special characters and update the value of the input.
     * @param event - The paste event.
     */
    handlePaste = (event) => {
        event.preventDefault();

        // Get the pasted text.
        const pastedText = event.clipboardData.getData('text');

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = pastedText;
        const decodedText = tempDiv.innerHTML || tempDiv.innerText || tempDiv.textContent;

        // Remove non-breaking spaces.
        const cleanText = StringHelpers.removeNonBreakingSpaces(decodedText || '');

        // Update the value of the input.
        event.target.value = cleanText;

        // Update the state.
        this.handleTextChange(cleanText);
    };

    render() {
        const { maxLength, disabled, readOnly, multiline, startAdornment, placeholder, startIcon, useTextarea, fieldType, className, endAdornment } =
            this.props;
        const { value = '', warnings, errors } = this.state;

        let adornment;
        if (startAdornment) {
            adornment = <InputAdornment position="start">{startAdornment}</InputAdornment>;
        } else if (startIcon) {
            adornment = (
                <InputAdornment position="start">
                    <Icon style={{ color: '#707070' }}>{startIcon}</Icon>
                </InputAdornment>
            );
        }

        // Read only, show value
        if (readOnly) {
            return <div className="input__copy__readonly">{value}</div>;
        }
        // Textarea
        else if (useTextarea) {
            return (
                <div className="input__copy ">
                    <textarea
                        ref={this.input}
                        value={value}
                        className="input__copy__textarea"
                        onChange={(event) => this.handleTextChange(event.target.value)}
                    />
                </div>
            );
        }
        // Not read only, show input
        else {
            return (
                <div className={classNames('input__copy', className)}>
                    <div className="input__copy__input">
                        <TextField
                            data-cy={`${this.props.dataCyPrefix}-textfield`}
                            inputRef={this.input}
                            value={value}
                            onChange={(event) => this.handleTextChange(event.target.value)}
                            margin="dense"
                            size="medium"
                            variant="outlined"
                            onInput={(e) => {
                                if (e.target.value && maxLength && maxLength > 0) {
                                    e.target.value = e.target.value.slice(0, maxLength);
                                }
                            }}
                            multiline={multiline}
                            disabled={disabled}
                            type={fieldType}
                            style={{ marginTop: 0, width: '100%' }}
                            slotProps={{
                                input: {
                                    maxLength: maxLength ? maxLength : 1000,
                                    startAdornment: adornment,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {endAdornment ? endAdornment : <React.Fragment>{maxLength ? `${maxLength - value.length}` : ''}</React.Fragment>}
                                        </InputAdornment>
                                    )
                                }
                            }}
                            placeholder={placeholder}
                            onPaste={this.handlePaste}
                        />
                    </div>

                    {warnings &&
                        warnings.map((warning, i) => (
                            <div className="input-field-set__helper-text input-field-set__helper-text--warning" key={'warning' + i}>
                                {warning}
                            </div>
                        ))}

                    {errors &&
                        errors.map((error, i) => (
                            <div className="input-field-set__helper-text input-field-set__helper-text--error" key={'warning' + i}>
                                {error}
                            </div>
                        ))}
                </div>
            );
        }
    }
}
